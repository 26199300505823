<template>
	<div id="groupStatistics">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;" @click="type=1">年级统计</a></el-breadcrumb-item>
				<el-breadcrumb-item v-if="type!=1"><a style="color: #000000;" @click="type=2">班级统计</a></el-breadcrumb-item>
				<el-breadcrumb-item v-if="type!=1&&type!=2"><a style="color: #000000;" @click="type=3">学科统计</a></el-breadcrumb-item>
				<el-breadcrumb-item v-if="type!=1&&type!=2&&type!=3"><a style="color: #000000;">小组统计</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content" v-if="type==1">
				<div class="class-list" v-if="gradeList.length">
					<div class="class-item m_b_20" v-for="(item,index) in gradeList" :key="index">
						<div style="padding: 15px 15px 20px 15px;">
							<div class="u-f-item">
								<i class="iconfont icon-banji11"></i>
								<span class="font_blod">{{item.name}}</span>
							</div>
							<div class="u-f-item u-f-jsb m_t_20">
								<div class="u-f-item p-l-25">
									<span class="font_20">{{item.score}}</span>
									<span class="font_12" style="color: #A6A6A6;margin-left: 10px;">分</span>
								</div>
								<!-- <div class="subject">英语</div> -->
							</div>
						</div>
						<div class="u-f-justify btn" style="padding: 15px 0;background-color: #FAFAFA;color: #505050;" @click="toClass(item.id)">查看详情</div>
					</div>
				</div>
				<el-empty :image-size="100" v-if="!gradeList.length" style="margin-top: 200px;"></el-empty>
			</div>
			<div class="content" v-if="type==2">
				<div class="class-list" v-if="classList.length">
					<div class="class-item m_b_20" v-for="(item,index) in classList" :key="index">
						<div style="padding: 15px 15px 20px 15px;">
							<div class="u-f-item">
								<i class="iconfont icon-banji11"></i>
								<span class="font_blod">{{item.name}}</span>
							</div>
							<div class="u-f-item u-f-jsb m_t_20">
								<div class="u-f-item p-l-25">
									<span class="font_20">{{item.score}}</span>
									<span class="font_12" style="color: #A6A6A6;margin-left: 10px;">分</span>
								</div>
								<!-- <div class="subject">英语</div> -->
							</div>
						</div>
						<div class="u-f-justify btn" style="padding: 15px 0;background-color: #FAFAFA;color: #505050;" @click="tosubject(item.id)">查看详情</div>
					</div>
				</div>
				<el-empty :image-size="100" v-if="!classList.length" style="margin-top: 200px;"></el-empty>
			</div>
			<div class="content" v-if="type==3">
				<div class="class-list" v-if="subjectList.length">
					<div class="class-item m_b_20" v-for="(item,index) in subjectList" :key="index">
						<div style="padding: 15px 15px 20px 15px;">
							<div class="u-f-item">
								<i class="iconfont icon-banji11"></i>
								<span class="font_blod">{{item.name}}</span>
							</div>
							<div class="u-f-item u-f-jsb m_t_20">
								<div class="u-f-item p-l-25">
									<span class="font_20">{{item.score}}</span>
									<span class="font_12" style="color: #A6A6A6;margin-left: 10px;">分</span>
								</div>
								<!-- <div class="subject">英语</div> -->
							</div>
						</div>
						<div class="u-f-justify btn" style="padding: 15px 0;background-color: #FAFAFA;color: #505050;" @click="togroup(item.id)">查看详情</div>
					</div>
				</div>
				<el-empty :image-size="100" v-if="!subjectList.length" style="margin-top: 200px;"></el-empty>
			</div>
			<div class="content" v-if="type==4">
				<div class="class-list" v-if="groupList.length">
					<div class="class-item m_b_20" v-for="(item,index) in groupList" :key="index">
						<div style="padding: 15px 15px 20px 15px;">
							<div class="u-f-item">
								<i class="iconfont icon-banji11"></i>
								<span class="font_blod">{{item.name}}</span>
							</div>
							<div class="u-f-item u-f-jsb m_t_20">
								<div class="u-f-item p-l-25">
									<span class="font_20">{{item.score}}</span>
									<span class="font_12" style="color: #A6A6A6;margin-left: 10px;">分</span>
								</div>
								<!-- <div class="subject">英语</div> -->
							</div>
						</div>
						<!-- <div class="u-f-justify btn" style="padding: 15px 0;background-color: #FAFAFA;color: #505050;" @click="back">返回</div> -->
					</div>
				</div>
				<el-empty :image-size="100" v-if="!groupList.length" style="margin-top: 200px;"></el-empty>
			</div>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			gradeList:[],
			classList:[],
			groupList:[],
			subjectList:[],
			type:1,
			grade_id:'',
			class_id:'',
			subject_id:''
		};
	},
	mounted() {
		this.gradeCensus()
	},
	methods: {
		gradeCensus(){
			this.$api.setting.gradeCensus({}).then(res=>{
				if(res.data.code==1){
					this.gradeList = res.data.data;
				}
			})
		},
		toClass(id){
			this.grade_id = id;
			this.$api.setting.classCensus({grade_id:id}).then(res=>{
				if(res.data.code==1){
					this.classList = res.data.data;
					this.type=2;
				}
			})
		},
		tosubject(id){
			this.class_id = id;
			this.$api.setting.subjectCensus({
				grade_id:this.grade_id,
				class_id:id
			}).then(res=>{
				if(res.data.code==1){
					this.subjectList = res.data.data;
					this.type=3;
				}
			})
		},
		togroup(id){
			this.subject_id = id;
			this.$api.setting.smallCensus({
				grade_id:this.grade_id,
				class_id:this.class_id,
				subject:id
			}).then(res=>{
				if(res.data.code==1){
					this.groupList = res.data.data;
					this.type=4;
				}
			})
		}
	}
};
</script>

<style lang="scss">
#groupStatistics {
	.content{
		background-color: #FFFFFF;
		height: 80vh;
		margin-top: 1.04166vw;
		padding: 1.302vw;
		overflow-y: auto;
		.class-list{
			display: flex;
			flex-wrap: wrap;
			.class-item{
				width: 23%;
				border: 1px solid #EBEDEE;
				margin-right: 20px;
				.icon-banji11{
					color: #60B2FF;
					font-size: 26px;
					margin-right: 5px;
				}
				.subject{
					background-color: #F1F1F1;
					font-size: 0.625vw;
					padding: 0.26vw 0.7812vw;
				}
				.btn{
					cursor: pointer;
				}
				.btn:hover{
					background-color: #EEEEEE!important;
				}
			}
			
		}
	}
}
</style>
